export const turnstileChallenge = (action) => {
  if (typeof turnstile === 'undefined') return

  return new Promise((resolve, reject) => {
    const options = {
      sitekey: "0x4AAAAAAABPqzmj1aU9y5-7",
      retry: "never",
      callback: (token) => resolve(token),
      'error-callback': (error) => {
        console.error(error);
        try {
          // Check if AWS RUM is already loaded
          if (window.awsRum) { window.awsRum.recordError(error) }
        } catch {}
        reject("Turnstile challenge failed. Please try again.")
      }
    }

    if (action) options.action = action
    turnstile.render('#cf-turnstile', options)
  });
}
